.icon-bx-wraper{
	// Style 1
	&.style-1{
		padding:40px;
		position: relative;
		transition: 0.5s;
		border-radius: var(--border-radius-base);
		@include respond('tab-land'){
			padding:30px;
		}
		
		.icon-content{
			.title{
				font-weight:600;
				color:#fff;
				@include respond('tab-port'){
					font-size:22px;
				}		
			}
			.text{
				font-size:14px;
				color:#fff;
				margin-bottom:0;
			}
		}
		.icon-media{
			width:90px;
			height:auto;
			img{
				background:#fff;
				padding:10px;
				margin-bottom:20px;
				box-shadow: 0px 3px 50px rgba(0, 0, 0, 0.2);
				transition: 0.5s;		
			}
		}
		.count{
		   position: absolute;
			top: 30px;
			right: 30px;
			font-weight: 700;
			font-size: 100px;
			opacity: 0.2;
			line-height: 1;
			margin-bottom: 0;
				@include respond('tab-port'){
					font-size:65px;
				}
		}
		&:hover{
			transform: translateY(-10px);
			.icon-media{
				img{
					animation: shake 1s infinite;
				}
			}
		}
		
	}
	&.style-2{
		padding:28px 53px;
		background:var(--rgba-primary-1);
		border-radius:var(--border-radius-base);
		margin-bottom:30px;
		transition: 0.5s;
			.icon-content{
				.text{
					margin-bottom: 0;
					a{
						color:#4A586F;
					}
				}
				.title{
					color: #fff;
					font-size: 28px;
					margin-bottom: 0;
					padding: 23px 0;
					display: flex;
					align-items: center;
					justify-content: center;
						@include respond('phone-land'){
							padding: 15px 0;
						}
				}
			}
		&:hover{
			background:var(--primary);
			transform: translateY(-10px);
			.icon-content{
				h5,
				p{
					a{
					color:#fff;
					}
				}
			}
		}	
	}
}

@-webkit-keyframes toTopFromBottom {
	49% {
		-webkit-transform: translateY(-100%); }
	50% {
		opacity: 0;
		-webkit-transform: translateY(100%); }
	51% {
		opacity: 1;
	}
}
@-moz-keyframes toTopFromBottom {
	49% {
		-moz-transform: translateY(-100%);
	}
	50% {
		opacity: 0;
		-moz-transform: translateY(100%);
	}
	51% {
		opacity: 1;
	}
}
@keyframes toTopFromBottom {
	49% {
		transform: translateY(-100%);
	}
	50% {
		opacity: 0;
		transform: translateY(100%);
	}
	51% {
		opacity: 1;
	}
}