p{
	font-size:16px;
}
.page-content {
    background-color: #fff !important;
}
.page-wraper {
    background-color: #fff !important;
}

.main-bnr{
	height: auto;
	position: relative;
	display: flex;
    align-items: center;
    overflow: hidden;
	padding-top: 50px;
	z-index:1;
    background: var(--primary);
    background-blend-mode: luminosity;
		.text{
			@include respond('phone'){
				font-size:14px;
			}
		}
		&:after{
		    content: '';
			position: absolute;
			top: 0;
			right: 0;
			background: var(--primary);
			transform: rotate(21.6deg) translate(130px, -90px);
			height: calc(150vh + 300px);
			width: calc(60vh + 160px);
			z-index:-1;
			
	
			@include respond('tab-land'){
				width: calc(45vh + 160px);
			}
			@include respond('tab-port'){
				height: calc(200vh + 300px);
				width: calc(35vh + 200px);
				transform: rotate(21.6deg) translate(183px, -90px);
			}
			@include respond('phone-land'){
				width: calc(15vh + 200px);
			}
			@include respond('phone'){
				width: calc(15vh + 200px);
			}
		}
	@include respond('tab-port'){
		height:auto !important;
		padding-top:135px;
	}
	.shape1 {
		position: absolute;
		top: 0;
		left: 0;
		width:230px;
		height:300px;
			@include respond('wide-desktop'){
				width:120px;
				height:140px;
			}
			@include respond('tab-land'){
				display:none;
			}
	}
	.shape2 {
		position: absolute;
		bottom: 0;
		right: 0;
		width:250px;
		height:500px;
		@include respond('wide-desktop'){
			width:200px;
			height:400px;
		}
		 @media only screen and (max-width: 1600px) {
			right:-20px;
		 }
		@include respond('wide-desktop'){
			width:150px;
			height:250px;
		}
	}
	.container,
	.container-fluid{
		position: relative;
		z-index: 3;
	}
	.sub-title{
		font-weight: 500;
		font-size: 18px;
		color:var(--primary);
	}
	
	h1{
		font-size: 65px;
		font-weight: 600;
		line-height: 1.2;
		margin-bottom: 20px;

		
		.text-line{
			position: relative;
			&:after{
				content: "";
				top: 75px;
				z-index: -1;
				right: 5px;
				height: 34px;
				width: 380px;
				position: absolute;
				background-size: 100%;
				background-repeat: no-repeat;
				background-position: left top;
			}
		}
	}
	.text{
	font-size: 18px;
    font-weight: 400;
    margin-bottom: 40px;
    line-height: 1.7;
    max-width: 575px;
	margin-bottom:5px;
		
		@media only screen and (max-width: 1191px){
			font-size: 16px;
		}
		@include respond('tab-port'){
			font-size: 15px;
			margin-bottom: 10px;
		}
		@include respond('phone-land'){
			width:100%;
		}
	}
	
	@include respond('tab-land'){
		height: 700px;
		h1{
			font-size: 50px;
		}
	}
   @media only screen and (max-width: 1024px) {
	 	height: 600px;
	 }
	@include respond('phone-land'){
		height: 820px;
		h1{
			font-size: 45px;
		}
	}
	@include respond('phone-land'){
		h1{
			font-size: 36px;
			margin-bottom:5px;
		}
	}
	.bottom-contact{
			span{
				color:var(--title);
			}
			a{
				color:#7F93B6;
				font-size:16px;
				font-weight:400;
				margin-right:2px;
			}
	}
}
.caart-box{
	.caart-box-1{
		padding:0px 30px;
		
	}
}
@keyframes shake{
	0% {
		transform: translate(1px, 1px) rotate(0deg);
	}
	10% {
		transform: translate(-1px, -2px) rotate(-1deg);
	}
	20% {
		transform: translate(-3px, 0px) rotate(1deg);
	}
	30% {
		transform: translate(3px, 2px) rotate(0deg);
	}
	40% {
		transform: translate(1px, -1px) rotate(1deg);
	}
	50% {
		transform: translate(-1px, 2px) rotate(-1deg);
	}
	60% {
		transform: translate(-3px, 1px) rotate(0deg);
	}
	70% {
		transform: translate(3px, 1px) rotate(-1deg);
	}
	70% {
		transform: translate(3px, 1px) rotate(-1deg);
	}
	80% {
		transform: translate(-1px, -1px) rotate(1deg);
	}
	90% {
		transform: translate(1px, 2px) rotate(0deg);
	}
	100% {
		transform: translate(1px, -2px) rotate(-1deg);
	}
}
/* @keyframes upToDown{
	49% {
		-webkit-transform: translateY(100%);
		transform: translateY(100%);
	}
	50% {
		opacity: 0;
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
	}
	51% {
		opacity: 1;
	}
} */



.dz-bnr-inr{
	.shape1{
		position: absolute;
		left: 0;
		top:0;
		z-index: 0;
		width:200px;
		height:auto;
		@include respond('laptop'){
			width: 150px;
			height:auto;
		}
		@media only screen and (max-width: 1191px){
			width: 150px;
			height:auto;
		}
		@include respond('phone'){
			width: 100px;
			height:auto;
		}
	}
	.shape2{
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 0;
		@include respond('laptop'){
			width: 120px;
		}
		@media only screen and (max-width: 1191px){
			width: 85px;
		}
		@include respond('phone'){
			width: 70px;
		}
	}
}

.banner-media{
    position: relative;
	margin-right:-80px;
	padding-top:60px;
		@include respond('wide-desktop'){
			margin-right:-60px;
		}
		@include respond('laptop'){
			margin-right:-0px;
		}
		@include respond('tab-port'){
			padding-top:20px;
		}
}
.bnr-blocks{
	li{
		position:absolute;
		&:nth-child(1){
			top:40%;
			left:0;
		}
		&:nth-child(2){
			top: 50%;
			right: -40px;
				@include respond('tab-land'){
					top: 60%;
					right: 10px;
				}
		}
		&:nth-child(3){
			bottom:12%;
			left:-70px;
				@include respond('phone-land'){
					left:10px;
				}
		}
	}
	@media only screen and (max-width: 1024px) {
		display:none;
	}
	
	
}
.bnr-block{
	padding: 18px 20px;
    background: var(--rgba-primary-1);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    width: fit-content;
    display: flex;
    align-items: center;
    backdrop-filter: blur(7.5px);
	i{
		width:40px;
		height:40px;
		line-height:40px;
		text-align:center;
		background:var(--primary);
		color:#fff;
		border-radius:6px;
		margin-right:15px;
	}
	.block-text{
		font-size: 14px;
		font-weight: 500;
		color: #142238;
		line-height:1.2;
	}
}

.bnr-block-pics{
	font-weight: 500;
	font-size: 16px;
	color: #142238;
	padding: 18px 20px;
	background: var(--rgba-primary-1);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
	border-radius: var(--border-radius-base);
	backdrop-filter: blur(7.5px);
	.twm-pics{
		display: flex;
		align-items: center;
		justify-content:center;
		padding-left:15px;
			span{
				display: inline-block;
				width: 45px;
				height: 45px;
				border-radius: 50%;
				border: 3px solid #fff;
				overflow: hidden;
				margin-left: -15px;
					img{
					height: auto;
					max-width: 100%;
					border: none;
					border-radius: 0;
					box-shadow: none;
					}
			}
	}
}

@-webkit-keyframes dZwobble {
  0% {
    -webkit-transform: rotate(-1deg) translate(-5px, -5px);
    transform: rotate(-1deg) translate(-5px, -5px); 
	}
  50% {
    -webkit-transform: rotate(1deg) translate(5px, 5px);
    transform: rotate(1deg) translate(5px, 5px); 
	}
  100% {
    -webkit-transform: rotate(-1deg) translate(-5px, -5px);
    transform: rotate(-1deg) translate(-5px, -5px); 
		} 
}
@-webkit-keyframes aniBnrShape2{
	100%{
		-webkit-transform: rotateY(360deg);
		transform: rotateY(360deg);
	}
}
@-webkit-keyframes aniBnrShape3{
	100%{
		-webkit-transform: rotateX(-360deg);
		transform: rotateX(-360deg);
	}
}
@-webkit-keyframes aniBnrShape4{
	0%{
		-webkit-transform: rotate(0) translate(2px, 2px) scale(1);
		transform: rotate(0) translate(2px, 2px) scale(1);
	}
	50%{
		-webkit-transform: rotate(50deg) translate(2px, 2px) scale(1.2);
		transform: rotate(50deg) translate(2px, 2px) scale(1.2);
	}
	100%{
		-webkit-transform: rotate(0) translate(2px, 2px) scale(1);
		transform: rotate(0) translate(2px, 2px) scale(1);
	}
}
@-webkit-keyframes aniBnrShape5{
	0%{
		-webkit-transform: skewY(0);
		transform: skewY(0);
	}
	50%{
		-webkit-transform: skewY(20deg);
		transform: skewY(20deg);
	}
	100%{
		-webkit-transform: skewY(0);
		transform: skewY(0);
	}
}
@-webkit-keyframes aniBnrShape7{
	100%{
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-webkit-keyframes aniBnrMedia{
	0%{
		-webkit-transform: rotate(1deg) translate(2px, 2px);
		transform: rotate(1deg) translate(2px, 2px);
	}
	50%{
		-webkit-transform: rotate(-1deg) translate(-2px, -2px);
		transform: rotate(-1deg) translate(-2px, -2px);
	}
	100%{
		-webkit-transform: rotate(1deg) translate(2px, 2px);
		transform: rotate(1deg) translate(2px, 2px);
	}
}
	



.contact-wrapper{
	.form-wrapper-box{
		margin-bottom: -180px;
		margin-top: 0;
	}
	.section-head{
		margin-bottom: 40px;
		.title{
			margin-bottom: 12px;
		}
		p {
			font-size: 18px;
			line-height: 1.6;
		}
		@include respond('phone'){
			margin-bottom: 30px;
			p{
				font-size: 16px;
			}
		}
	}
	@include respond('tab-port'){
		.form-wrapper-box{
			margin-bottom: 0;
		}
	}
}
.form-wrapper-box{
	border-radius: 20px;
	box-shadow: 0px 25px 50px rgba(9, 9, 21, 0.1);
	padding: 50px;
	background-color: #fff;
	
	@include respond('laptop'){
		padding: 40px;
	}
	@include respond('phone'){
		padding: 30px;
	}
	
	.section-head{
		margin-bottom: 30px;
		.title{
			margin-bottom: 5px;
		}
		p{
			font-weight: 400;
			font-size: 20px;
			color: #444444;
		}
		@include respond('laptop'){
			p{
				font-size: 18px;
			}
		}
		@include respond('tab-land'){
			p{
				font-size: 16px;
			}
		}
		@include respond('phone'){
			margin-bottom: 25px;
		}
	}
	.form-control{
		background-color: transparent;
		z-index: 0 !important;
		@include transitionMedium;
	}
	textarea.form-control{
		height: 150px;
		padding: 20px 20px;
	}
	.input-group {
		margin-bottom: 30px;
		
		@include respond('phone'){
			margin-bottom: 20px;
		}
	}
	.input-area{
		position: relative;
		
		label{
			position: absolute;
			font-size: 16px;
			font-weight: 400;
			top: 15px;
			left: 15px;
			color: #555555;
			@include transitionFast;
			background-color: #fff;
			display: block;
			padding: 0 10px;
			@include respond('tab-land'){
				font-size: 14px;
			}
		}
		&.focused{
			label{
				position: absolute;
				font-size: 13px;
				top: -8px;
				color: $primary;
				z-index: 2;
			}
		}
		@include respond('phone'){
			label{
				top: 12px;
			}
		}
	}
}


.dz-media{
	&.style-1{
		overflow: visible;
		img{
			width:100%;
			height:100%;
			border-radius: var(--border-radius-base);
		}
		.text{
			background:var(--primary);
			text-align:center;
			position:absolute;
			bottom:65px;
			left:-40px;
			padding:18px 34px;
			border-radius: var(--border-radius-base);
			font-size:18px;
			font-weight:500;
			color:#F4FAFF;
			animation: dZwobble2 4s infinite;
			@include respond('tab-land'){
				left:0px;
			}
			@include respond('phone'){
				display:none;	
			}
		}	
	}
}
.dz-contant{
	&.style-1{
		padding-left:60px;
			@include respond('tab-land'){
				padding-left:40px;
			}
			@include respond('tab-port'){
				padding-left:0;
			}
	}
}


@keyframes dZwobble2 {
	0% {
		transform: rotate(-1deg) translate(-5px, -5px);
	}
	50%{
		transform: rotate(1deg) translate(5px, 5px);
	}
	100%{
		transform: rotate(-1deg) translate(-5px, -5px);
	}
}


.num-pagination{
	&.style-1{
		.btn-next,
		.btn-prev{
			background: var(--rgba-primary-1);
			padding: 15px 20px;
			border-radius: 6px;
			position:absolute;
			top:60%;
			transition: 0.5s;
			z-index:99;
			i{
			color: var(--primary);
			font-size: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			}
		}
		.btn-prev{
			left:12%;
				@media only screen and (max-width: 1600px) {
					left:5%;
				}
				@include respond('tab-land'){
					left:0%;
				}
				@include respond('tab-port'){
					left:3%;
				}
			&:hover{
				background:var(--primary);
				transform: translateX(-10px);
					i{
						color:#fff;	
					}
			}
		}
		.btn-next{
			right:12%;
				@media only screen and (max-width: 1600px) {
					right:5%;
				}
				@include respond('tab-land'){
					right:0%;
				}
				@include respond('tab-port'){
					right:3%;
				}
			&:hover{
				background:var(--primary);
				transform: translateX(10px);
					i{
						color:#fff;	
					}
			}
		}
	}
	@include respond('phone'){
		display:none;
	}
}
.center-line{
	position:relative;
	z-index:1;
			@include respond('phone'){
				margin: 0 -5px 10px;
			}
	&:after {
		content: '';
		position: absolute;
		top: 23%;
		left: 50%;
		height: 50%;
		border: 1px solid rgba(0, 0, 0, 0.1);
			@include respond('phone'){
				display:none;
			}
	}

}


.bnr-search-bar{
	background:#fff;
	padding:10px 20px;
	border-radius: var(--border-radius-base);
	box-shadow: 0px 10px 60px rgba(28, 65, 128, 0.25);
	margin-bottom:20px;
	margin-right:40px;
		@include respond('tab-land'){
			padding:5px 10px;
		}
		@include respond('tab-port'){
			margin-right:0;
		}
		@include respond('phone'){
			padding:10px;
		}
		@media only screen and (max-width: 1024px) {
			margin-right:0px;

		}
	.search-bar{
		display:flex;
		align-items:center;
		gap:10px;
		margin-left:10px;
			@include respond('phone'){
				margin-left:0;
				justify-content:unset;
			}
			span{
				i{
					color:var(--primary);
				}
			}
		.icon-content{
			input{
				border: 0;
				background: #fff;
				padding: 0;
				box-shadow: none;
				color:var(--primary);
				font-size: 16px;
				&::placeholder{
					color:#7F93B6;
					font-size: 16px;
				}
			}
		
		}
	}
	.btn{
		font-size:14px;
		font-weight:600;
		border-radius:8px;
	}
}

.overlay-box {
	&.style-1{
		position: relative;
		overflow: hidden;
		padding: 20px 30px;
		min-height: 280px;
		border-radius: var(--border-radius-base);
			@include respond('phone'){
				min-height: 200px;
			}
		.contant-box{
			height:100%;
			.crypto-info{
				display:flex;
				align-items:center;
				justify-content: space-between;
				h5{
				font-weight: 600;
				font-size: 28px;
				color: #F68E00;
				margin-bottom:0;
					&.text-purple{
						color:#6670FF;
					}
				}
				.text{
					color: #553810;
					font-size:14px;
					font-weight:400;
				}		
			}
			.crypto-media{
				position: absolute;
				bottom: 0;
				right: 0;
				transform: translate(40px, 30px) rotate(16.9deg);
					@include respond('phone'){
						width:35%;
					}
			}
			.btn-link{
				position: absolute;
				bottom: 25px;
				left: 30px;
				color: #553810;
				font-size: 15px;
				font-weight: 400;
			}
		}
	}
	&.style-2 {
	    position: relative;
		padding: 20px 30px;
		min-height: 280px;
		border-radius: var(--border-radius-base);
		display: flex;
		align-items: center;
		justify-content: center;
		.title{
			font-weight: 600;
			font-size: 45px;
			line-height: 1.2;
			display:flex;
			align-items:center;
			justify-content:center;
				@include respond('phone-land'){
					font-size:38px;
				}
				@include respond('phone'){
					font-size:32px;
				}
		}
		.btn-link{
			position: absolute;
			bottom: 25px;
			left: 30px;
			font-weight: 400;
			font-size: 15px;
			color: #553810;
			i{
				margin-left:7px;
			}
			&.lg{
				position: unset;
				color: #fff;
				font-size: 18px;
				font-weight: 500;
				i{
				margin-left:15px;
				}			
			}
		}
	
	}
}

.bg-light-yellow{
	background:#FFF7D7;
}
.bg-light-purple{
	background:#F1F2FE;
}


.widget-box{
	&.style-1{
		display: flex;
		padding:10px;
		border-radius:13px;
		align-items: center;

		.dz-media{
			height: 90px;
			margin-right: 15px;
			min-width: 90px;
			position: relative;
			img{
				width: 100%;
				height: 100%;
				border-radius: var(--border-radius-base);
			}
			&::before{
				content: '';
				position: absolute;
				top: 0;
				left: -100%;
				z-index: 2;
				display: block;
				width: 50%;
				height: 100%;
				background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
				background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
				@include transitionSlow;
			}
		}
		.dz-info{
			.date{
				a{
				    font-weight: 600;
					font-size: 12px;
					background: var(--primary);
					color: #fff;
					padding: 5px 10px;
					border-radius: 3px;
				}
			}
			.title{
			    font-weight: 600;
				color: #523325;
				margin-top: 10px;
				margin-bottom: 0;
			}
		}
		&:hover{
			.dz-media::before{
				left: 100%;
				right: 0;
			}
		}
	}
}

.crypto-wrapper{
	margin-top: -62px;
	@include respond('tab-land'){
		margin-top: -42px;
	}
}	  

.bg-clr-sky{
	background: #4885ED;
	.count{
		-webkit-text-stroke: 1px #fff;
		-webkit-text-fill-color: #4885ED;
	}
}
.bg-clr-pink{
	background:#DB77C4;
	.count{
		-webkit-text-stroke: 1px #fff;
		-webkit-text-fill-color:#DB77C4;
	}
}
.bg-clr-green{
	background:#74B24E;
	.count{
		-webkit-text-stroke: 1px #fff;
		-webkit-text-fill-color:#74B24E;
	}
}

.footer-action{
	padding:45px 70px;
	border-radius:8px;
	background:var(--primary);
	margin-bottom:-9%;
		@include respond('tab-port'){
			padding:30px 20px;
		}
		@include respond('phone'){
			margin-bottom:40px;
		}
	.inner-content{	
		.title{
			font-size:40px;
			font-weight:700;
			color:#fff;
				@include respond('phone-land'){
					font-size:30px;
				}
				@include respond('phone'){
					font-size:24px;
				}		
		}
	}
}





















.call-to-action{
	.title {
		font-size:55px;
		@include respond('laptop'){
		font-size:52px;
		}
		@include respond('tab-land'){
			font-size:42px;
		}
		@include respond('phone'){
			font-size:24px;
		}
	}
}
.clients-logo{
	.logo-main{
		opacity:0.2;
		@include transitionMedium;
		
		&:hover{
			opacity:1;
		}
	}
}

.dz-meta{
	.post-author{
		img{
			border:2px solid #fff;
			border-radius:100%;
			width:50px;

		}
	}
}

.info-box{
	&.style-1{
	color:#fff !important;
	padding:45px;	
	background:var(--primary);
	border-radius: var(--border-radius-base);
		@include respond('tab-land'){
			padding:30px;
		}
		@include respond('phone'){
			padding:20px;
		}
	
	}
	.widget{
		margin-bottom:30px !important;
		ul{
			li{
			border-bottom: 1px solid rgba(255, 255, 255, 0.3);
			width: 80%;
			padding:20px 0;
					@include respond('phone'){
						padding:10px 0;
						margin-bottom:0;
					}
				p{
				color:#fff;
				opacity:0.6;
				margin-bottom:5px;
				}
				h6 {
				font-size: 16px;
				font-weight: 500;
				}
			}
		}
	}
}


.dz-social{
	&.style-1{
		.social-icon{
			display:flex;
			align-items:center;
			gap:15px;
			li{
				background:rgba(255,255,255,0.1);
				width:40px;
				height:40px;
				border-radius:8px;
				text-align:center;
				position:relative;
				z-index:1;
				a{
					color:#fff;
					text-align:center;
					color: #fff;
					text-align: center;
					line-height: 40px;
				}
				&:after {
					content: "";
					position: absolute;
					height: 40px;
					width: 0;
					background-color: var(--secondary);
					left: 0px;
					top: 0;
					z-index: -1;
					border-radius: var(--border-radius-base);
					-webkit-transition: all 0.5s;
					-ms-transition: all 0.5s;
					transition: all 0.5s;
					z-index:-1;
				}
				&:hover{
					&:after {
						width:100%;
					
					}
				}
			}
		}
	}
}

.form-wrapper{
	&.style-1{
		padding-left: 30px;
			@include respond('tab-port'){
				padding-left:0;
			}
	}
}

.content-inner{
	&.style-1{
		padding:85px  0 0 0  !important;
	
	}
}

.dz-meta{
	margin-bottom: 10px;
	
	ul{
		margin:0;
		padding:0;
		
		li{
			display: inline-block;
			position: relative;
			font-size: 15px;
			margin-right: 25px;
			margin-left:5px;
				@include respond('tab-port'){
					font-size:13px;
				}
			&:after{
				content: "";
				height: 6px;
				width: 6px;
				background-color: var(--primary);
				position: absolute;
				top: 50%;
				right: -20px;
				border-radius: 50%;
				transform: translateY(-50%);
					@include respond('phone'){
						height: 4px;
						width: 4px;
						right: -9px;
					}
			}
			
			&:last-child{
				margin-right:0;
				padding-right: 0;
				&:after{
					content: none;
				}
			}
			i{
				font-size: 16px;
				margin-right: 2px;
				color: var(--primary);
				transform: scale(1.3);
				position: relative;
				top: 2px;
				padding-right: 0.2rem;
			}
			a{
				color:inherit;
			}
			@include respond('phone'){
				margin-right: 15px;
				padding-right: 5px;
			}
			
		}
		.post-author{
			img{
				@include respond('phone'){	
					display:none;
				}
			
			}
		}	
	}	
}


button.btn.btn-primary{
	&.style-2 {
		
		&.two{
			display:none;
			border-radius:0 6px 6px 0 !important ;
			@include respond('phone-land'){
				display:unset !important;
			}
		}
		@include respond('phone-land'){
			display:none;
		}
	}
}

.contant-box{
	&.style-1{
		display:flex;
		align-items:center;
		@include respond('phone'){
			display:unset;
		}
	}
	&.style-2{
		padding:25px 14px;
		border-radius:var(--border-radius-base);
		@include respond('tab-land'){	
			padding: 20px 14px;
		}
		@include respond('phone'){	
			padding:10px;
		}
	}
}

//Animation Cursor
.cursor {
	position: fixed;
	width: 30px;
	height: 30px;
	border: 1px solid var(--primary);
	border-radius: 50%;
	left: 0;
	top: 0;
	z-index: 999998;
	pointer-events: none;
	transform: translate(-50%, -50%);	
	transition: 0.10s;
	
}
.cursor2 {
	position: fixed;
	width: 12px;
	height: 12px;
	border: 1px solid #c6c6c6;
	border-radius: 50%;
	left: 0;
	top: 0;
	z-index: 999998;
	pointer-events: none;
	transform: translate(-50%, -50%);
	background: #333;	
	transition: 0.05s;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}