.job-bx{
	&.style-1{
		box-shadow: 0px 10px 20px rgba(0,0 , 0, 0.10);
		border-radius: var(--border-radius-base);
		padding: 20px;
		background: #fff;
		margin-bottom: 30px;
		transition: 0.5s;
		margin-top: 20px;
			.media{
				width:80px;
				height:auto;

				img{
					margin-top: -38px;
					padding: 10px;
					background: #fff;
					box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.1);
					border-radius: var(--border-radius-base);
					transition: 0.6s;				
				}
			}
		ul{
			display: flex;
			align-items: center;
			gap: 15px;
			li{
				a{
				font-size:12px;
				font-weight:500;
				}
				.job-time{
					background: var(--rgba-primary-2);
					border-radius:4px;
					padding:4px 10px;
				}
			}
		}
		.job-contant{
			margin-bottom:25px;
			.job-title{
				font-size: 18px;
				margin-bottom:5px;
			}
			.text{
				font-size:13px;
			}
		}
		.jobs-amount{
			display:flex;
			margin-bottom:10px;
			align-items:center;
			justify-content: space-between;
			.amount{
				font-size: 18px;
				margin-bottom:0;
				color:var(--primary);
				span{
					font-weight: 400;
					font-size: 15px;
					color: #142238;		
				}
			}
			.btn{
				padding:0;
				width:38px;
				height:38px;
				line-height:38px;
			}
		}
		&:hover{
			transform: translateY(-10px);
		}
		
	}
}