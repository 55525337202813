.dz-card{
	&.style-1{
		background: #fff;
		padding: 25px;
		border-radius:var(--border-radius-base);
		margin-top: 25px;
		box-shadow: 0 0 50px rgba(0,0,0,0.1);
			@include respond ('phone'){
				padding:20px;
			}
		
		.dz-media{
		    margin-top: -45px;
			border-radius: 10px;
			overflow: hidden;
			box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.10);
			margin-bottom: 20px;		
			.date{
				a{
					color: #fff;
					padding: 6px 14px;
					border-radius: 5px;
					font-size: 13px;
					font-weight: 600;
					position: absolute;
					top: 12px;
					left: 12px;
					background: var(--body-color);
					backdrop-filter: blur(7.5px);
					opacity: 0.8;
				}
			}
			&::before{
				content: '';
				position: absolute;
				top: 0;
				left: -100%;
				z-index: 2;
				display: block;
				width: 50%;
				height: 100%;
				background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
				background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
				@include transitionSlow;
			}
		}
		&:hover{
			.dz-media::before{
				left: 100%;
				right: 0;
			}
		}
		.dz-info{
			padding:0;
			&.shadow{
				box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
			}
		}
		.dz-title{
			font-weight: 600;
			margin-bottom:5px;
			line-height:1.2;
		}
		p{
		font-weight: 400;
		margin-bottom:22px;
		}
		.dz-meta{
			ul{
				li{
					i{
					font-size: 13px;
					color: var(--primary);
					top: 0;
						@include respond ('phone'){
							font-size:11px;
						}
					}
				}
			}
		}
		&.blog-half{
			padding:0;
			margin-top:0;
			.dz-info{
				padding:30px;
					@include respond('phone-land'){
						border-radius: 0 0 6px 6px;
					}
					@include respond ('phone'){
						padding:20px;
					}
			}
			.dz-media{
				margin-bottom:0;
				margin-top:0;
				border-radius:var(--border-radius-base) 0 0 var(--border-radius-base);
					@include respond('phone-land'){
						border-radius:var(--border-radius-base) var(--border-radius-base) 0 0;
					}
			}
			
		}			
	}
	
	&.style-2{
		background:#fff;
		padding:30px;
		padding-bottom:20px;
		border-radius:var(--border-radius-base);
		.dz-info{
			padding:0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.bottom-contact{
				display: flex;
				align-items:center;
				.dz-media{
					width:55px;
					height:55px;
					margin-right:15px;
					border-radius:50%;
					img{
						width:100%;
						height:100%;
						object-fit: cover;
					}
				}
				
				.dz-title{
					font-size:18px;
					font-weight:600;
					margin-bottom:0;
				}
				.text{
					margin-bottom:0;
					color:var(--primary);
				}
			}
			svg{
				opacity: 0.2;
				width:80px;
				@include respond('phone'){
					width:50px;	
				}
			}
		}
		.dz-text{
			p{
			font-size: 18px;
			color: var(--body-color);
			font-style: italic;
				@include respond('phone'){
					font-size:14px;
				}
			}
		}
	}
}
.swiper-container{
	.dz-card {
		&.style-1 {
			margin: 15px;
		}
	}
}